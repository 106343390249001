import React from "react"
import SocialLinks from "./social"

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <SocialLinks />
        <div className="credits">
          <p>
            Illustrations :{" "}
            <a
              href="https://clementinerobach.jimdofree.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Clémentine Robach
            </a>
          </p>
          <p>
            Site et photo :{" "}
            <a
              href="https://patrizioperucchi.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Patrizio Perucchi
            </a>
          </p>
        </div>
      </footer>
    </>
  )
}

export default Footer
