import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const ListItem = props => {
  const { data } = props
  return (
    <li className="social-links__list">
      <a
        href={data.url}
        title={data.name}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img className="social-links__image" src={data.icon} alt={data.name} />
      </a>
    </li>
  )
}

const SocialLinks = () => {
  const data = useStaticQuery(graphql`
    query SocialQuery {
      site {
        siteMetadata {
          social {
            name
            url
            icon
          }
        }
      }
    }
  `)

  const items = data.site.siteMetadata.social
  let list = []
  items.forEach((e, i) => {
    list.push(<ListItem key={e.url + "-" + e.icon + "-" + i} data={e} />)
  })
  return <ul className="social-links">{list}</ul>
}

export default SocialLinks
